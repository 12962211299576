import { createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";

import { DATE_FORMAT } from "const";
import { clientReportingApi } from "services/client-reporting/client-reporting-servise";
import type { RootState } from "store/store";
import type { IGetAnalyticResponse } from "types/interface";

import { authSelectors } from "../auth";

/**
 * Возвращает данные на определенную дату и сгруппированные по типу продуктов
 */
export const getAnalyticThunk = createAsyncThunk(
  "briefcase/getMfTable",
  async (
    {
      dateFrom,
      dateTo,
      skip,
    }: {
      dateFrom: Date;
      dateTo: Date;
      skip?: boolean;
    },
    api
  ) => {
    const fansyId = authSelectors.selectFancyId(api.getState() as RootState)!;

    if (skip) {
      return {
        classes: {
          data: [],
        },
        products: {
          data: [],
        },
        assets: {
          data: [],
        },
        hasStocks: false,
        hasBonds: false,
        hasFunds: false,
        hasPIFs: false,
      } as IGetAnalyticResponse;
    }
    return api
      .dispatch(
        clientReportingApi.endpoints.getAnalytic.initiate({
          investmentId: null,
          investorId: fansyId,
          dateFrom: format(dateFrom, DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE),
          dateTo: format(dateTo, DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE),
        })
      )
      .unwrap();
  }
);
