import { URLS } from "const";
import { SummaryTypeEnum } from "services/summary/types";

import { MAIN_TABS, TM_TABS } from "./consts";
import type { ParsedRouteParamsFromTMUrl } from "./types";

/** Строит урл для кнопки инвестировать */
const buildURlForInvestButton = (
  type: SummaryTypeEnum,
  contractId?: number
) => {
  switch (type) {
    case SummaryTypeEnum.DU: {
      if (contractId) {
        return buildURLForTM(
          `${contractId}${URLS.SHOWCASE.TRUST_MANAGEMENT.CASH_DEPOSIT}`
        );
      }
      return URLS.SHOWCASE.TRUST_MANAGEMENT.MAIN;
    }
    case SummaryTypeEnum.IIS:
    case SummaryTypeEnum.DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION:
    case SummaryTypeEnum.DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED: {
      return URLS.SHOWCASE.IIS.CASH_DEPOSIT;
    }
    case SummaryTypeEnum.PIF: {
      return URLS.SHOWCASE.PIF.PURCHASE;
    }
    // Такого кейса не может быть, но нужно обработать для TS
    case SummaryTypeEnum.BRIEFCASE: {
      return `${MAIN_TABS.BRIEFCASE}`;
    }
  }
};

/** Строит урл для вкладки "ДУ" и ее внутренних табов */
const buildURLForTM = (tab: string, contractId: number | null = null) =>
  contractId
    ? `${URLS.SHOWCASE.TRUST_MANAGEMENT.MAIN}/${contractId}/${tab}`
    : `${URLS.SHOWCASE.TRUST_MANAGEMENT.MAIN}/${tab}`;

/**
 * Парсинг урла на вкладке ДУ
 */
const parseTrustManagementUrl = (
  url: string
): ParsedRouteParamsFromTMUrl | null => {
  const childRoutes = Object.values(TM_TABS).join("|");

  const re = new RegExp(
    `^showcase\\/(trust-management)(\\/(?<contractId>\\d+))?\\/(?<tmTab>${childRoutes})$`
  );
  const match = re.exec(url);

  return match ? (match.groups as ParsedRouteParamsFromTMUrl) : null;
};

export const tabsUtils = {
  buildURlForInvestButton,
  buildURLForTM,
  parseTrustManagementUrl,
};
