import { getIsSingleBriefcaseItem } from "components/personal-area/briefcase/briefcase";
import { DATE_FORMAT } from "const";
import { useAppSelector } from "hooks/redux";
import { briefcaseApi } from "services/briefcase/service";
import type { IGetBriefcaseStructureResponse } from "services/briefcase/types";
import { SummaryTypeEnum } from "services/summary/types";
import { useOnDate } from "store/reducers/briefcase-summary/hooks";
import { clientReportingSelectors } from "store/reducers/client-reporting/reducer";
import { todayLocaleRu } from "utils/date";
import { formatDate, parseDate } from "utils/formatters";
import type { RemoteData } from "utils/fp";
import { pipe, remote } from "utils/fp";

import {
  filterMatchedProductsAndContractsForShow,
  getBriefcaseInfo,
  getBriefcasePieDataFromAnalytics,
  getBriefcaseTotal,
  getMatchedProductsAndContractsByTypes,
} from "./utils";

export const useBriefcase = () => {
  const [onDate, setOnDate] = useOnDate();

  const { contracts } = useAppSelector(
    clientReportingSelectors.selectClientReportingState
  );

  const summaryWithContracts: RemoteData<IGetBriefcaseStructureResponse> =
    briefcaseApi.useGetBriefcaseStructureQuery({ onDate });

  const data = pipe(
    remote.combine({
      summaryWithContracts,
    }),
    remote.map(({ summaryWithContracts }) => {
      //      console.log("summaryWithContracts.analytic.assets.data[4].value: " + summaryWithContracts.analytic.assets.data[4].value);

      const productsAndContractsForShow =
        filterMatchedProductsAndContractsForShow(
          summaryWithContracts.matchedProductsAndContracts
        );
      const tmContracts = getMatchedProductsAndContractsByTypes(
        productsAndContractsForShow,
        [SummaryTypeEnum.DU]
      );
      const iiaContracts = getMatchedProductsAndContractsByTypes(
        productsAndContractsForShow,
        [
          SummaryTypeEnum.IIS,
          SummaryTypeEnum.DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION,
          SummaryTypeEnum.DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED,
        ]
      );
      const mfContracts = getMatchedProductsAndContractsByTypes(
        productsAndContractsForShow,
        [SummaryTypeEnum.PIF]
      );

      const briefcase = getBriefcaseInfo(
        mfContracts,
        tmContracts,
        iiaContracts,
        summaryWithContracts.multipleMainData,
        summaryWithContracts.mfTable,
        parseDate(onDate, DATE_FORMAT.FULL_DATE_WITH_DASH)
      );

      const isSingleBriefcaseItem = getIsSingleBriefcaseItem(briefcase);
      const briefcaseTotal = getBriefcaseTotal(
        summaryWithContracts.totalMainData
      );

      return {
        briefcase,
        isSingleBriefcaseItem,
        pie: getBriefcasePieDataFromAnalytics(
          summaryWithContracts.analytic.products.data,
          summaryWithContracts.matchedProductsAndContracts,
          parseDate(onDate, DATE_FORMAT.FULL_DATE_WITH_DASH)
        ),
        datePicker: {
          // PieCard принимает даты в разном формате.
          dateFrom: formatDate(
            summaryWithContracts.minDate ?? todayLocaleRu,
            DATE_FORMAT.FULL_DATE_WITH_DASH
          ),
          dateTo: formatDate(
            summaryWithContracts.maxDate ?? todayLocaleRu,
            DATE_FORMAT.FULL_DATE_WITH_DASH
          ),
        },
        profit: briefcaseTotal.income.amount,
        total: briefcaseTotal.assetValue.amount,
        profitability: briefcaseTotal.profitability,
      };
    }),
    remote.map((data) => ({
      ...data,
      briefcase: data.briefcase.map((el) => ({
        ...el,
        items: el.items,
      })),
    }))
  );

  return {
    data,
    contracts,
    onDate,
    setOnDate,
    summaryWithContracts,
  };
};
