import { format, min } from "date-fns";
import isEmpty from "lodash/isEmpty";

import { authSelectors } from "store/reducers/auth";

import { DATE_FORMAT } from "../../const";
import { getProductsByTypes } from "../../pages/briefcase/utils";
import { getCommonBriefcaseDataThunk } from "../../store/reducers/briefcase-common/thunks";
import { getAnalyticThunk } from "../../store/reducers/client-reporting/thunks";
import { getMfTableThunk } from "../../store/reducers/mutual-funds/thunks";
import type { RootState } from "../../store/store";
import { authApi } from "../auth";
import { clientReportingApi } from "../client-reporting/client-reporting-servise";
import { SummaryTypeEnum } from "../summary/types";

import type {
  IGetBriefcaseStructureBaseResponse,
  IGetBriefcaseStructureResponse,
  IGetMFBriefcaseStructureRequest,
  IGetMFBriefcaseStructureResponse,
} from "./types";

export const briefcaseApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Структура портфеля ПИФ
     */
    getMFBriefcaseStructure: build.query<
      IGetMFBriefcaseStructureResponse,
      IGetMFBriefcaseStructureRequest
    >({
      queryFn: async ({ onDate: localeRuOnDate }, api) => {
        const mutualFundsState = (api.getState() as RootState).mutualFunds;
        const fansyId = authSelectors.selectFancyId(
          api.getState() as RootState
        )!;

        const {
          onDate,
          minDate,
          maxDate,
          multipleMainData,
          matchedProductsAndContracts,
          summaryProductsWithTypes,
        } = await api
          .dispatch(
            getCommonBriefcaseDataThunk({
              onDate: localeRuOnDate,
              minDate: mutualFundsState.minDate,
              maxDate: mutualFundsState.maxDate,
              types: [SummaryTypeEnum.PIF],
              selectedContractId: null,
            })
          )
          .unwrap();

        const mfTable = await api
          .dispatch(
            getMfTableThunk({
              skip: summaryProductsWithTypes.length === 0,
              dateFrom: minDate,
              dateTo: min([onDate, maxDate]),
            })
          )
          .unwrap();

        const analytic = await api
          .dispatch(
            clientReportingApi.endpoints.getAnalytic.initiate({
              investmentId: null,
              investorId: fansyId,
              dateFrom: format(
                minDate,
                DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE
              ),
              dateTo: format(
                min([onDate, maxDate]),
                DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE
              ),
            })
          )
          .unwrap();

        return {
          data: {
            minDate,
            maxDate,
            mfTable,
            multipleMainData,
            matchedProductsAndContracts,
            analytic,
          },
        };
      },
    }),
    /**
     * Структура общего портфеля
     */
    getBriefcaseStructure: build.query<
      IGetBriefcaseStructureResponse,
      IGetMFBriefcaseStructureRequest
    >({
      queryFn: async ({ onDate: localeRuOnDate }, api) => {
        const briefcaseSummaryState = (api.getState() as RootState)
          .briefcaseSummary;
        const fansyId = authSelectors.selectFancyId(
          api.getState() as RootState
        )!;

        const {
          onDate,
          minDate,
          maxDate,
          multipleMainData,
          matchedProductsAndContracts,
          summaryProductsWithTypes,
        } = await api
          .dispatch(
            getCommonBriefcaseDataThunk({
              onDate: localeRuOnDate,
              minDate: briefcaseSummaryState.minDate,
              maxDate: briefcaseSummaryState.maxDate,
              types: [
                SummaryTypeEnum.PIF,
                SummaryTypeEnum.IIS,
                SummaryTypeEnum.DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION,
                SummaryTypeEnum.DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED,
                SummaryTypeEnum.DU,
              ],
              selectedContractId: null,
            })
          )
          .unwrap();

        const analytic = await api
          .dispatch(
            getAnalyticThunk({
              dateFrom: minDate,
              dateTo: min([onDate, maxDate]),
            })
          )
          .unwrap();

        const hasNoPif = isEmpty(
          getProductsByTypes(summaryProductsWithTypes, [SummaryTypeEnum.PIF])
        );

        // данные портфеля в разрезе пифов
        const mfTable = await api
          .dispatch(
            getMfTableThunk({
              skip: hasNoPif,
              dateFrom: minDate,
              dateTo: min([onDate, maxDate]),
            })
          )
          .unwrap();

        // сводная информация о стоимости активов, доходе и доходности для портфеля
        const totalMainData = await api
          .dispatch(
            clientReportingApi.endpoints.getMainData.initiate({
              investorId: fansyId,
              dateFrom: format(
                minDate,
                DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE
              ),
              dateTo: format(
                min([onDate, maxDate]),
                DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE
              ),
              investmentId: null,
            })
          )
          .unwrap();

        return {
          data: {
            minDate,
            maxDate,
            mfTable,
            multipleMainData,
            matchedProductsAndContracts,
            analytic,
            totalMainData,
            summaryProducts: summaryProductsWithTypes,
          },
        };
      },
    }),
    /**
     * Структура портфеля ИИС
     */
    getIIABriefcaseStructure: build.query<
      IGetBriefcaseStructureBaseResponse,
      IGetMFBriefcaseStructureRequest
    >({
      queryFn: async ({ onDate: localeRuOnDate }, api) => {
        const individualInvestmentState = (api.getState() as RootState)
          .individualInvestment;

        const {
          minDate,
          maxDate,
          multipleMainData,
          matchedProductsAndContracts,
        } = await api
          .dispatch(
            getCommonBriefcaseDataThunk({
              onDate: localeRuOnDate,
              minDate: individualInvestmentState.minDate,
              maxDate: individualInvestmentState.maxDate,
              types: [
                SummaryTypeEnum.IIS,
                SummaryTypeEnum.DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION,
                SummaryTypeEnum.DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED,
              ],
              selectedContractId: null,
            })
          )
          .unwrap();

        return {
          data: {
            minDate,
            maxDate,
            multipleMainData,
            matchedProductsAndContracts,
          },
        };
      },
    }),

    /**
     * Структура портфеля ДУ
     */
    getTMBriefcaseStructure: build.query<
      IGetBriefcaseStructureBaseResponse,
      IGetMFBriefcaseStructureRequest & {
        selectedContractId: number | null;
      }
    >({
      queryFn: async ({ onDate: localeRuOnDate, selectedContractId }, api) => {
        const tmState = (api.getState() as RootState).trustManagement;

        const {
          minDate,
          maxDate,
          multipleMainData,
          matchedProductsAndContracts,
        } = await api
          .dispatch(
            getCommonBriefcaseDataThunk({
              onDate: localeRuOnDate,
              minDate: tmState.minDate,
              maxDate: tmState.maxDate,
              types: [SummaryTypeEnum.DU],
              selectedContractId,
            })
          )
          .unwrap();

        return {
          data: {
            minDate,
            maxDate,
            multipleMainData,
            matchedProductsAndContracts,
          },
        };
      },
    }),
  }),
});
