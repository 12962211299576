import type { FC } from "react";
import React from "react";

import classNames from "classnames";
import { Button } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { Paper } from "components/common/paper";
import { Currency } from "const";
import type { IBriefcaseGroupItem } from "entities/briefcase";
import { SummaryTypeEnum } from "services/summary/types";
import { tabsUtils } from "store/reducers/tabs";
import type { InvestMfRoutingState } from "types/routing-state";
import {
  checkAvailableMutualFundById,
  generateAvailableMFLinkByFundId,
} from "utils/check-available-mutual-funds";
import { numberFormat } from "utils/formatters";

import "./index.scss";

const typeTitleMap: Record<SummaryTypeEnum, string> = {
  [SummaryTypeEnum.IIS]: "Стратегия ИИС",
  [SummaryTypeEnum.DISSOLVED_IIS_WITH_UNDETERMINED_TYPES_OF_DEDUCTION]:
    "Стратегия ИИС",
  [SummaryTypeEnum.DISSOLVED_IIS_WITH_DEDUCTION_AMOUNT_OF_INCOME_RECEIVED]:
    "Стратегия ИИС",
  [SummaryTypeEnum.DU]: "Стратегия ДУ",
  [SummaryTypeEnum.PIF]: "Паевый фонд",
  // Такого кейса не может быть, но нужно обработать для TS
  [SummaryTypeEnum.BRIEFCASE]: "Общий портфель",
};

interface IBriefcaseSingleProps {
  item: IBriefcaseGroupItem;
  onOpenModal?: (id: number) => void;
}

export const BriefcaseSingle: FC<IBriefcaseSingleProps> = ({
  item,
  onOpenModal,
}) => {
  const navigate = useNavigate();
  const profit = item.pif?.profit || item.totalYieldMoney || 0;
  const profitYield = item.pif?.yield || item.totalYield || 0;

  const isAvailableFund = checkAvailableMutualFundById(item.id);

  const handleInvestButtonClick = () => {
    if (isAvailableFund || item.type !== SummaryTypeEnum.PIF) {
      navigate(tabsUtils.buildURlForInvestButton(item.type, item.id), {
        state: { investProductId: String(item.id) } as InvestMfRoutingState,
      });
      return;
    }
    onOpenModal?.(item.id);
  };

  return (
    <Paper className="briefcase-single">
      <span className="briefcase-single__type">{typeTitleMap[item.type]}</span>
      <span className="briefcase-single__title">{item.title}</span>
      <div className="briefcase-single__info">
        <span className="briefcase-single__info-title">В портфеле</span>
        <div className="briefcase-single__info-main">
          <span className="briefcase-single__info-sum">
            {numberFormat({
              value: item.totalCost,
              postfix: Currency.Ruble,
            })}
          </span>
          <span
            className={classNames({
              "briefcase-single__info-profit": true,
              "briefcase-single__info-profit--positive": profit > 0,
              "briefcase-single__info-profit--negative": profit < 0,
            })}
          >
            {`${numberFormat({
              value: profit,
              postfix: Currency.Ruble,
              hasPrefix: true,
            })} (${numberFormat({
              value: profitYield,
              postfix: "%",
            })})`}
          </span>
        </div>
        {item.pif ? (
          <div className="briefcase-single__info-pif">
            <div className="briefcase-single__info-pif-other">
              {`${numberFormat({
                value: item.pif.cost,
                postfix: Currency.Ruble,
              })} ● ${numberFormat({
                value: item.pif.quantity,
                postfix: "шт.",
                digits: 7,
              })}`}
            </div>
            <div className="briefcase-single__info-pif-date">
              {item.comment}
            </div>
          </div>
        ) : (
          <div className="briefcase-item__comment">{item.comment}</div>
        )}
      </div>
      <div
        className={classNames("briefcase-single__actions", {
          "briefcase-single__actions--single": !isAvailableFund,
        })}
      >
        {/* FIXME: Как временная мера, скрываем кнопку "Подробнее" для ИИС, ДУ. */}
        {isAvailableFund && (
          <Button
            as="a"
            title="Подробнее"
            size="xs"
            color="secondary"
            className="briefcase-item__button--invisible"
            href={generateAvailableMFLinkByFundId(item.id)}
            target="_blank"
          />
        )}
        <Button
          title="Инвестировать"
          size="xs"
          onClick={handleInvestButtonClick}
        />
      </div>
    </Paper>
  );
};
