import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { QueryStatus } from "@reduxjs/toolkit/query";

import type { Nullable } from "../../entities/common";

export type StandardRemoteError = FetchBaseQueryError | SerializedError;

type RemoteBase = {
  isUninitialized: boolean;

  isFetching?: boolean;

  isLoading: boolean;

  isError: boolean;

  isSuccess: boolean;

  status: QueryStatus;

  refetch?: () => void;
};

export type RemoteInitial = RemoteBase & {
  error?: undefined;

  data: undefined;
};

export type RemotePending<A> = RemoteBase & {
  data?: A;
};

export type RemoteError<E> = RemoteBase & {
  error: E;

  data: undefined;
};

export type RemoteSuccess<A> = RemoteBase & {
  error?: undefined;

  data: A;
};

export type RemoteData<A, E = StandardRemoteError> =
  | RemoteInitial
  | RemotePending<A>
  | RemoteError<E>
  | RemoteSuccess<A>;

const initial: RemoteInitial = {
  isUninitialized: true,
  isError: false,
  isFetching: false,
  isSuccess: false,
  isLoading: false,
  data: undefined,
  error: undefined,
  status: QueryStatus.uninitialized,
};

const pending = <A>(data?: A): RemotePending<A> => ({
  isUninitialized: false,
  isError: false,
  isFetching: true,
  isSuccess: false,
  isLoading: true,
  data,
  status: QueryStatus.pending,
});

const error = <E extends StandardRemoteError>(error: E): RemoteError<E> => ({
  isUninitialized: false,
  isError: true,
  isFetching: false,
  isSuccess: false,
  isLoading: false,
  data: undefined,
  error,
  status: QueryStatus.rejected,
});

const success = <A>(data: A): RemoteSuccess<A> => ({
  isUninitialized: false,
  isError: false,
  isFetching: false,
  isSuccess: true,
  isLoading: false,
  data,
  error: undefined,
  status: QueryStatus.fulfilled,
});

const isInitial = <A, E = StandardRemoteError>(
  data: RemoteData<A, E>
): data is RemoteInitial => data.status === QueryStatus.uninitialized;

const isPending = <A, E = StandardRemoteError>(
  data: RemoteData<A, E>
): data is RemotePending<A> => data.status === QueryStatus.pending;

const isError = <A, E = StandardRemoteError>(
  data: RemoteData<A, E>
): data is RemoteError<E> => data.status === QueryStatus.rejected;

const isSuccess = <A, E = StandardRemoteError>(
  data: RemoteData<A, E>
): data is RemoteSuccess<A> => data.status === QueryStatus.fulfilled;

const map =
  <A, B>(f: (a: A) => B) =>
  (data: RemoteData<A>): RemoteData<B> =>
    ({
      ...data,
      data:
        data.data !== null && data.data !== undefined
          ? f(data.data)
          : data.data,
    } as RemoteData<B>);

const fold =
  <A, B, E = StandardRemoteError>(
    onInitial: () => B,
    onPending: (data: Nullable<A>) => B,
    onError: (e: E) => B,
    onSuccess: (a: A) => B
  ) =>
  (data: RemoteData<A, E>) => {
    if (isInitial(data)) {
      return onInitial();
    }
    if (isError(data)) {
      return onError(data.error);
    }
    if (isSuccess(data)) {
      return onSuccess(data.data);
    }
    return onPending(data.data);
  };

const foldSuccess =
  <A, B, E = StandardRemoteError>(onElse: () => B, onSuccess: (a: A) => B) =>
  (data: RemoteData<A, E>) =>
    isSuccess(data) ? onSuccess(data.data) : onElse();

const foldError =
  <A, B, E = StandardRemoteError>(onElse: () => B, onError: (a: E) => B) =>
  (data: RemoteData<A, E>) =>
    isError(data) ? onError(data.error) : onElse();

const foldPending =
  <A, B, E = StandardRemoteError>(
    onElse: () => B,
    onPending: (a: Nullable<A>) => B
  ) =>
  (data: RemoteData<A, E>) =>
    isPending(data) ? onPending(data.data) : onElse();

const foldInitial =
  <A, B, E = StandardRemoteError>(onElse: () => B, onInitial: () => B) =>
  (data: RemoteData<A, E>) =>
    isInitial(data) ? onInitial : onElse();

const getOrElse =
  <A, E = StandardRemoteError>(onElse: () => A) =>
  (data: RemoteData<A, E>) =>
    isSuccess(data) ? data.data : onElse();

const combine = <T extends Record<string, RemoteData<unknown>>>(
  data: T
): RemoteData<{
  [K in keyof T]: T[K] extends RemoteData<infer R> ? R : never;
}> => {
  type Result = RemoteData<{
    [K in keyof T]: T[K] extends RemoteData<infer R> ? R : never;
  }>;

  const entries = Object.entries(data);
  const successCount = entries.filter(([, item]) => isSuccess(item)).length;

  if (successCount === entries.length) {
    return success(
      entries.reduce((acc, [key, item]) => ({ ...acc, [key]: item.data }), {})
    ) as Result;
  }

  const errorEntry = entries
    .map(([, item]) => item)
    .find((item) => isError(item));
  if (errorEntry) {
    return error((errorEntry as RemoteError<never>).error);
  }

  const pendingWithDataCount = entries.filter(
    ([, item]) =>
      isPending(item) && item.data !== null && item.data !== undefined
  ).length;

  if (pendingWithDataCount === entries.length) {
    return pending(
      entries.reduce((acc, [key, item]) => ({ ...acc, [key]: item.data }), {})
    ) as Result;
  }

  const pendingCount = entries.filter(([, item]) => isPending(item)).length;
  if (pendingCount > 0) {
    return pending();
  }

  return initial;
};

export const remote = {
  initial,
  pending,
  error,
  success,
  isInitial,
  isPending,
  isError,
  isSuccess,
  map,
  fold,
  foldSuccess,
  foldError,
  foldPending,
  foldInitial,
  getOrElse,
  combine,
};
