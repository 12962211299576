import { useCallback } from "react";

import type { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { useAppDispatch, useAppSelector } from "../hooks/redux";

import type { RootState } from "./store";

export const createUseStoreField =
  <T, S extends (state: RootState) => T>(
    selector: S,
    action: ActionCreatorWithPayload<T>
  ) =>
  (): [T, (updated: T) => void] => {
    const field = useAppSelector(selector);
    const dispatch = useAppDispatch();

    const setField = useCallback(
      (updated: T) => dispatch(action(updated)),
      [dispatch]
    );

    return [field, setField];
  };
