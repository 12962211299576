import { createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";

import { DATE_FORMAT } from "const";
import { clientReportingApi } from "services/client-reporting/client-reporting-servise";
import type { RootState } from "store/store";

/**
 * Возвращает данные на определенную дату и сгруппированные по типу продуктов
 */
export const getMfTableThunk = createAsyncThunk(
  "briefcase/getMfTable",
  async (
    {
      dateFrom,
      dateTo,
      skip,
    }: {
      dateFrom: Date;
      dateTo: Date;
      skip: boolean;
    },
    api
  ) => {
    const fansyId = (api.getState() as RootState).auth.fansyId!;

    if (skip) {
      return { data: [], totalPages: 0, currentPage: 0 };
    }
    return api
      .dispatch(
        clientReportingApi.endpoints.getMfTable.initiate({
          investmentId: null,
          investorId: fansyId,
          dateFrom: format(dateFrom, DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE),
          dateTo: format(dateTo, DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE),
          page: 1,
          pageSize: 0,
        })
      )
      .unwrap();
  }
);
